import React from 'react';
import uiUx from '../assets/images/ui ux.png';
import webDevelop from '../assets/images/web.png';
import mobileDevlop from '../assets/images/mobile.png';
import deployment from '../assets/images/deployment.png';
import softwareDevlop from '../assets/images/software devlop.png';
import softwareDesign from '../assets/images/software design.png';
import generativeAI from '../assets/images/generative ai.png';

const expertise_cards = [
  {
    id: 1,
    title: 'Generative AI',
    description: 'Unlock the power of AI: Get integrated the powerful large language models and customized AI models for your business needs.',
    icon: generativeAI,
    size:'w-[41.66px] h-[41.67px]'
  },
  {
    id: 2,
    title: 'Web Development',
    description: 'We provide end to end web solutions with enterprise technologies including MERN Stack and Java Microservices.',
    icon: webDevelop,
    size:'w-[43.75px] h-[43.75px]'
  },
  {
    id: 3,
    title: 'Mobile Application Development',
    description: 'Build your dream app with us—where stunning design meets seamless functionality.',
    icon: mobileDevlop,
    size:'w-[27.08px] h-[45.83px]'
  },
  {
    id: 4,
    title: 'Custom Software Development',
    description: 'Tailor your success with custom software, perfectly crafted to fit your business needs.',
    icon: softwareDevlop,
    size:'w-[39.58px] h-[41.67px]'
  },
  {
    id: 6,
    title: 'Software Architect and Design',
    description: 'Shape the future of your tech with our cutting-edge software architecture and design services.',
    icon: softwareDesign,
    size:'w-[43.75px] h-[43.75px]'
  },

  {
    id: 1,
    title: 'UI UX Designing',
    description: 'Transform your idea or existing application with our modern UI and UX design services.',
    icon: uiUx,
    size:'w-[41.67px] h-[41.68px]'
  },


  {
    id: 4,
    title: 'Deployment',
    description: 'Launch your app effortlessly with our reliable and streamlined deployment services with end to end pipeline with cloud platforms.',
    icon: deployment,
    size:'w-[41.67px] h-[33.33px]'
  },

];

const Expertise = () => {
  return (
    <div id="services" className="relative flex flex-col justify-between h-full max-w-6xl px-5 sm:px-11 mx-auto mt-7 mb-6">
      <div className="relative text-center mb-10">
        <h1 className="inline-block text-3xl sm:text-5xl font-bold leading-tight text-[#4F6370]">Our Services </h1>
        <div className="absolute w-[calc(100%-625px)] h-[1.1px] bg-[#0EA288] left-1/2 transform -translate-x-1/2 bottom-[-6px]"></div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
        {expertise_cards.map((expertise_card, index) => (
          <div
            key={expertise_card.id}
            className={`relative flex items-center bg-white border border-[#0EA288] text-[#4F6370] rounded-lg p-6 hover:bg-[#0EA288] hover:text-[#FFFFFF] transition duration-300 ease-in-out w-full max-w-full md:max-w-[508px] h-auto card cursor-text ${
              index === expertise_cards.length - 1 && expertise_cards.length % 2 !== 0 ? 'md:col-span-2 md:justify-center' : ''
            }`}
          >
            <div className="flex-shrink-0 h-full flex items-center">
              <img
                src={expertise_card.icon}
                alt={expertise_card.title}
                className={`${expertise_card.size} card-icon`}
              />
            </div>
            <div className="ml-4 sm:ml-6 text-left">
              <h2 className="card-title text-[#0EA288] text-lg sm:text-[22px] font-bold leading-tight mb-2">{expertise_card.title}</h2>
              <p className="text-base sm:text-[20px] font-normal leading-tight">{expertise_card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Expertise;
