import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';  // Import specific icons

const ContactPage = () => {
    // Define the primary theme color as a variable for easy reuse
    const themeColor = "#0EA288"; // Button and icon color

    return (
        <section className="py-16" id="contact">
            <div className="container mx-auto px-4">
                <div className="text-[#4F6370] text-center mb-12">
                    <SectionTitle Title="Let’s Talk" />
                </div>
                <div className="flex justify-center">
                    <div className="grid lg:grid-cols-2 gap-8 justify-items-center">
                        {/* Google Map Embed with a marker */}
                        <div className="w-full h-96 lg:h-auto relative border border-[#0EA288] rounded-lg shadow-lg lg:max-w-md">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.491598472998!2d73.09955777512265!3d33.59432764535862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x21e8350d026f7be7%3A0xe7916f129dcb5d07!2sNovaSync%20Dynamics%20Private%20Limited!5e0!3m2!1sen!2s!4v1694953351773!5m2!1sen!2s"
                                width="100%"
                                height="100%"
                                allowFullScreen={true}
                                loading="lazy"
                                className="border-0 rounded-lg"
                                style={{ minHeight: '450px' }}
                            ></iframe>

                            {/* Get Directions Button */}
                            <div className="absolute bottom-4 right-4">
                                <a
                                    href="https://www.google.com/maps/dir/?api=1&destination=33.5654373,73.1525625"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="px-4 py-2 bg-[#0EA288] text-white font-bold rounded-lg shadow-lg hover:bg-green-600"
                                >
                                    Get Directions
                                </a>
                            </div>
                        </div>

                        {/* Contact Info Section */}
                        <div className=" justify-content-between md:mt-0 mt-20">
                            <div className="space-y-10">
                                {/* Address */}
                                <div
                                    className="border border-[#0EA288] rounded-lg p-4 shadow-lg w-full lg:max-w-md flex items-center space-x-4 min-h-[120px]">
                                    <FaMapMarkerAlt size={24} className="text-[#0EA288]"/>
                                    <div>
                                        <h2 className="text-xl font-bold text-[#4F6370]">NovaSync Dynamics Private
                                            Limited, Islamabad, Punjab</h2>
                                        <span className="text-[#4F6370]">Office Address</span>
                                    </div>
                                </div>

                                {/* Email */}
                                <div
                                    className="border border-[#0EA288] rounded-lg p-4 shadow-lg w-full lg:max-w-md flex items-center space-x-4 min-h-[120px]">
                                    <FaEnvelope size={24} className="text-[#0EA288]"/>
                                    <div>
                                        <a href="mailto:info@novasyncdynamics.com"
                                           className="text-lg font-semibold text-[#4F6370]">
                                            info@novasyncdynamics.com
                                        </a>
                                        <span className="block text-[#4F6370]">Contact Mail</span>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="border border-[#0EA288] rounded-lg p-4 shadow-lg w-full lg:max-w-md flex items-center space-x-4 min-h-[120px]">
                                    <FaPhoneAlt size={24} className="text-[#0EA288]" />
                                    <div>
                                        <a href="tel:0515428246" className="text-lg font-semibold text-[#4F6370]">(051) 5428246</a>
                                        <span className="block text-[#4F6370]">Office Contact</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// SectionTitle component to style the section header
const SectionTitle = (props) => {
    return (
        <div className="flex justify-center">
            <div className="w-full lg:w-1/2 text-center">
                <h2 className="text-3xl font-bold text-[#4F6370] mb-4">{props.Title}</h2>
                <p className="text-lg text-[#4F6370]">Where Innovation Meets Execution: Delivering Software that Performs.</p>
            </div>
        </div>
    );
}

export default ContactPage;
